import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ColorsCard from "../../../components/CardColor/CardColor.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InlineNotification mdxType="InlineNotification">
      <p>{`This page contains information for Legion Phoenix (Version 2) design system. If you wish to see Legion UI (Version 1) design system please visit this `}<a parentName="p" {...{
          "href": "https://legion-site-2hxh21viz-telkom-design.vercel.app/"
        }}>{`link`}</a>{`.`}</p>
    </InlineNotification>
    <br />
    <h2>{`Usage`}</h2>
    <p>{`Colors system leverages a purposeful set of color styles as the perfect starting point for any brand or project.
When it comes to color, contrast is critical for ensuring text is legible.`}</p>
    <div className="divi" />
    <h2>{`Primary Colors`}</h2>
    <p>{`The primary color is your “brand” color, and is used across all interactive elements such as buttons, links,
inputs, etc. This color can define the overall feel and can elicit emotion.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="primary25" mdxType="ColorsCard" />
  <ColorsCard variant="primary50" mdxType="ColorsCard" />
  <ColorsCard variant="primary100" mdxType="ColorsCard" />
  <ColorsCard variant="primary200" mdxType="ColorsCard" />
  <ColorsCard variant="primary300" mdxType="ColorsCard" />
  <ColorsCard variant="primary400" mdxType="ColorsCard" />
  <ColorsCard variant="primary500" mdxType="ColorsCard" />
  <ColorsCard variant="primary600" mdxType="ColorsCard" />
  <ColorsCard variant="primary700" mdxType="ColorsCard" />
  <ColorsCard variant="primary800" mdxType="ColorsCard" />
  <ColorsCard variant="primary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="primary25">...</Box>
  <Box background="primary50">...</Box>
  <Box background="primary100">...</Box>
  <Box background="primary200">...</Box>
  <Box background="primary300">...</Box>
  <Box background="primary400">...</Box>
  <Box background="primary500">...</Box>
  <Box background="primary600">...</Box>
  <Box background="primary700">...</Box>
  <Box background="primary800">...</Box>
  <Box background="primary900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Secondary Palette`}</h2>
    <p>{`The secondary colors is color highlight or complement the primary color.
These are to be used sparingly to make the UI elements stand out. These colors are also usually defined in the brand guidelines.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="secondary25" mdxType="ColorsCard" />
  <ColorsCard variant="secondary50" mdxType="ColorsCard" />
  <ColorsCard variant="secondary100" mdxType="ColorsCard" />
  <ColorsCard variant="secondary200" mdxType="ColorsCard" />
  <ColorsCard variant="secondary300" mdxType="ColorsCard" />
  <ColorsCard variant="secondary400" mdxType="ColorsCard" />
  <ColorsCard variant="secondary500" mdxType="ColorsCard" />
  <ColorsCard variant="secondary600" mdxType="ColorsCard" />
  <ColorsCard variant="secondary700" mdxType="ColorsCard" />
  <ColorsCard variant="secondary800" mdxType="ColorsCard" />
  <ColorsCard variant="secondary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="secondary25">...</Box>
  <Box background="secondary50">...</Box>
  <Box background="secondary100">...</Box>
  <Box background="secondary200">...</Box>
  <Box background="secondary300">...</Box>
  <Box background="secondary400">...</Box>
  <Box background="secondary500">...</Box>
  <Box background="secondary600">...</Box>
  <Box background="secondary700">...</Box>
  <Box background="secondary800">...</Box>
  <Box background="secondary900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Tertiary Palette`}</h2>
    <p>{`The tertiary color is a neutral color and is the foundation of the color system.
Almost everything in UI design — text, form fields, backgrounds, dividers, outline card — are usually gray.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="tertiary25" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary50" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary100" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary200" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary300" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary400" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary500" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary600" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary700" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary800" mdxType="ColorsCard" />
  <ColorsCard variant="tertiary900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="tertiary25">...</Box>
  <Box background="tertiary50">...</Box>
  <Box background="tertiary100">...</Box>
  <Box background="tertiary200">...</Box>
  <Box background="tertiary300">...</Box>
  <Box background="tertiary400">...</Box>
  <Box background="tertiary500">...</Box>
  <Box background="tertiary600">...</Box>
  <Box background="tertiary700">...</Box>
  <Box background="tertiary800">...</Box>
  <Box background="tertiary900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Semantic Colors`}</h2>
    <p>{`Semantic colours communicate important status to the user such as success, information,
warning or error. These colours should be used paired with text, an icon or both to
fulfill their purpose in the best way possible.`}</p>
    <h2>{`Success`}</h2>
    <p>{`Success colors communicate a positive action, postive trend, or a successful confirmation.
If you’re using green as your primary color, it can be helpful to introduce a different
hue for your success green.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="success25" mdxType="ColorsCard" />
  <ColorsCard variant="success50" mdxType="ColorsCard" />
  <ColorsCard variant="success100" mdxType="ColorsCard" />
  <ColorsCard variant="success200" mdxType="ColorsCard" />
  <ColorsCard variant="success300" mdxType="ColorsCard" />
  <ColorsCard variant="success400" mdxType="ColorsCard" />
  <ColorsCard variant="success500" mdxType="ColorsCard" />
  <ColorsCard variant="success600" mdxType="ColorsCard" />
  <ColorsCard variant="success700" mdxType="ColorsCard" />
  <ColorsCard variant="success800" mdxType="ColorsCard" />
  <ColorsCard variant="success900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="success25">...</Box>
  <Box background="success50">...</Box>
  <Box background="success100">...</Box>
  <Box background="success200">...</Box>
  <Box background="success300">...</Box>
  <Box background="success400">...</Box>
  <Box background="success500">...</Box>
  <Box background="success600">...</Box>
  <Box background="success700">...</Box>
  <Box background="success800">...</Box>
  <Box background="success900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Warning`}</h2>
    <p>{`Warning colors can communicate that an action is potentially destructive or “on-hold”.
These colors are commonly used in confirmations to grab the users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="warning25" mdxType="ColorsCard" />
  <ColorsCard variant="warning50" mdxType="ColorsCard" />
  <ColorsCard variant="warning100" mdxType="ColorsCard" />
  <ColorsCard variant="warning200" mdxType="ColorsCard" />
  <ColorsCard variant="warning300" mdxType="ColorsCard" />
  <ColorsCard variant="warning400" mdxType="ColorsCard" />
  <ColorsCard variant="warning500" mdxType="ColorsCard" />
  <ColorsCard variant="warning600" mdxType="ColorsCard" />
  <ColorsCard variant="warning700" mdxType="ColorsCard" />
  <ColorsCard variant="warning800" mdxType="ColorsCard" />
  <ColorsCard variant="warning900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="warning25">...</Box>
  <Box background="warning50">...</Box>
  <Box background="warning100">...</Box>
  <Box background="warning200">...</Box>
  <Box background="warning300">...</Box>
  <Box background="warning400">...</Box>
  <Box background="warning500">...</Box>
  <Box background="warning600">...</Box>
  <Box background="warning700">...</Box>
  <Box background="warning800">...</Box>
  <Box background="warning900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Information`}</h2>
    <p>{`Information colors can communicate that an action is informative section or`}<br parentName="p"></br>{`
`}{`these colors are commonly used in awareness for users’ attention.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="information25" mdxType="ColorsCard" />
  <ColorsCard variant="information50" mdxType="ColorsCard" />
  <ColorsCard variant="information100" mdxType="ColorsCard" />
  <ColorsCard variant="information200" mdxType="ColorsCard" />
  <ColorsCard variant="information300" mdxType="ColorsCard" />
  <ColorsCard variant="information400" mdxType="ColorsCard" />
  <ColorsCard variant="information500" mdxType="ColorsCard" />
  <ColorsCard variant="information600" mdxType="ColorsCard" />
  <ColorsCard variant="information700" mdxType="ColorsCard" />
  <ColorsCard variant="information800" mdxType="ColorsCard" />
  <ColorsCard variant="information900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="information25">...</Box>
  <Box background="information50">...</Box>
  <Box background="information100">...</Box>
  <Box background="information200">...</Box>
  <Box background="information300">...</Box>
  <Box background="information400">...</Box>
  <Box background="information500">...</Box>
  <Box background="information600">...</Box>
  <Box background="information700">...</Box>
  <Box background="information800">...</Box>
  <Box background="information900">...</Box>
`}</code></pre>
    <br />
    <br />
    <h2>{`Error`}</h2>
    <p>{`Error colors are used across error states and in “destructive” actions.
They communicate a destructive/negative action, such as removing a user from your team.`}</p>
    <div className="grid-color-container">
  <ColorsCard variant="error25" mdxType="ColorsCard" />
  <ColorsCard variant="error50" mdxType="ColorsCard" />
  <ColorsCard variant="error100" mdxType="ColorsCard" />
  <ColorsCard variant="error200" mdxType="ColorsCard" />
  <ColorsCard variant="error300" mdxType="ColorsCard" />
  <ColorsCard variant="error400" mdxType="ColorsCard" />
  <ColorsCard variant="error500" mdxType="ColorsCard" />
  <ColorsCard variant="error600" mdxType="ColorsCard" />
  <ColorsCard variant="error700" mdxType="ColorsCard" />
  <ColorsCard variant="error800" mdxType="ColorsCard" />
  <ColorsCard variant="error900" mdxType="ColorsCard" />
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  <Box background="error25">...</Box>
  <Box background="error50">...</Box>
  <Box background="error100">...</Box>
  <Box background="error200">...</Box>
  <Box background="error300">...</Box>
  <Box background="error400">...</Box>
  <Box background="error500">...</Box>
  <Box background="error600">...</Box>
  <Box background="error700">...</Box>
  <Box background="error800">...</Box>
  <Box background="error900">...</Box>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      